import {RwLogLevels} from "@/app/RwEnums";
import { PODDefaultSettings } from "@/app/dem/ProofOfDelivery/ProofOfDeliveryEnums";

export class RwConstants {

    static flexVersion = "2024.08.260"; //Do not edit - gets replaced with proper version in CI build
    static LogLevel = RwLogLevels.Info;
    static isDevMode = (process.env.NODE_ENV === "development");

    // Core API ...
    private static corePROD = "https://coreApi.roadwarrior.app";
    private static coreTEST = "https://coreTest.roadwarrior.app";
    private static coreTESTLINUX = "https://rwcoretestlinux.azurewebsites.net";
    private static coreDEV = "https://rwcoredev.azurewebsites.net";
    private static podDEV = "https://rwpoddev.azurewebsites.net";
    private static coreRico = "http://192.168.84.23:5000";
    private static coreSabina = "http://127.0.0.1:5000";
    private static coreTommy = "http://192.168.0.243:5000";

    private static UseProdChargifyOverride = true;

    static CoreUri =
        (RwConstants.isDevMode)
            ? RwConstants.coreDEV  // Used locally
            : RwConstants.corePROD; // Used on build

    static CoreSocketUri = RwConstants.getSocketBase(RwConstants.CoreUri);

    // Chargify API v2
    static ChargifyLocalDevUrlV2 = "https://roadwarrior-dev.chargifypay.com/subscribe/c3j85ctyj2vw/team_pro_go";
    static ChargifyLocalProdUrlV2 = "https://roadwarrior.chargifypay.com/subscribe/x82nb2kvp3q7/team-pro-go";
    static ChargifyBetaUrlV2 = "https://roadwarrior.chargifypay.com/subscribe/dhrfkgh59xz9/team-pro-go";
    static ChargifyFlexTestUrl = "https://roadwarrior.chargifypay.com/subscribe/rq2vy4rxcchg/team-pro-go";

    // Prod urls per domain
    static ChargifyProdFlexUrlV2 = "https://roadwarrior.chargifypay.com/subscribe/ccvsvjxhcsnm/team-pro-go";
    static ChargifyProdTeamUrlV2 = "https://roadwarrior.chargifypay.com/subscribe/2n6phfwzh92p/team-pro-go";
    static ChargifyProdFedexUrlV2 = "https://roadwarrior.chargifypay.com/subscribe/wwfr6p84w2yh/team-pro-go";
    static ChargifyProdOnTracUrlV2 = "https://roadwarrior.chargifypay.com/subscribe/282g3ndzszzq/team-pro-go";
    static ChargifyProdUserUrlV2 = "https://roadwarrior.chargifypay.com/subscribe/2n7gv3fg8y6c/team-pro-go";
    static ChargifyProdBetaUrlV2 = "https://roadwarrior.chargifypay.com/subscribe/dhrfkgh59xz9/team-pro-go";

    // Prod Trial urls
    static ChargifyLocalProdTrialUrl = "https://roadwarrior.chargifypay.com/subscribe/3cvcft47g8gz/team-pro-go";
    static ChargifyFlexTestProdTrialUrl = "https://roadwarrior.chargifypay.com/subscribe/z5gs3m5y75qf/team-pro-go";
    static ChargifyFlexProdTrialUrl = "https://roadwarrior.chargifypay.com/subscribe/jqqzbh6dtw3q/team-pro-go";
    static ChargifyTeamProdTrialUrl = "https://roadwarrior.chargifypay.com/subscribe/4jtjcgzwnztt/team-pro-go";
    static ChargifyFedexProdTrialUrl = "https://roadwarrior.chargifypay.com/subscribe/thfjhgprhx6v/team-pro-go";
    static ChargifyOnTracProdTrialUrl = "https://roadwarrior.chargifypay.com/subscribe/drhk69c9y8pd/team-pro-go";
    static ChargifyUserProdTrialUrl = "https://roadwarrior.chargifypay.com/subscribe/gb6pwn7bwzwm/team-pro-go";

    static useDevChargify = (RwConstants.CoreUri === RwConstants.coreDEV || RwConstants.CoreUri === RwConstants.coreTommy || RwConstants.CoreUri === RwConstants.podDEV) && !RwConstants.UseProdChargifyOverride;

    static get TrialUrl() {
        if (this.isDevMode) {
            return this.ChargifyLocalProdTrialUrl;
        }
        const domain = window.location.host.split('.')[0].toLowerCase();
        switch (domain) {
            case "flextest":
                return RwConstants.ChargifyFlexTestProdTrialUrl;

            default:
            case "flex":
                return RwConstants.ChargifyFlexProdTrialUrl;
            case "team":
                return RwConstants.ChargifyTeamProdTrialUrl;
            case "fedex":
                return RwConstants.ChargifyFedexProdTrialUrl;
            case "ontrac":
                return RwConstants.ChargifyOnTracProdTrialUrl;
            case "user":
                return RwConstants.ChargifyUserProdTrialUrl;
        }
    }

    // Dev/Prod
    static EmptyGuid = "00000000-0000-0000-0000-000000000000";
    static SignupUrl: string = (RwConstants.isDevMode)
        ? (RwConstants.CoreUri === RwConstants.coreTEST ?
            RwConstants.ChargifyLocalProdUrlV2
            : RwConstants.ChargifyLocalDevUrlV2)
        : RwConstants.ChargifyBetaUrlV2;
    static CJS_PublicKey = RwConstants.useDevChargify ? 'chjs_4fqfvdbwtx2sgy9nbckfhb7m' : 'chjs_sg992kd6xrr3b66tv4nc6xxd';
    static NetTimeout = 60000;
    static OptTimeout = 200000;
    static ExtendedTimeout = 30000;
    static UploadTimeout = 90000;
    static FedexTimeout = 360000;
    static ShortTimeout = 5000;
    static PolyLassoResolution = 10.0;
    static HideFedexIntegration: boolean = false;
    static EmptyString = "";


    // SKUs
    static SKU_Legacy = "team.pro.monthly";
    static SKU_ProGo = "team.pro.go";
    static SKU_Flex = "flex.pro.monthly";
    static SKU_Trial = "flex.pro.trial";

    // Base PP
    static PPB = 14.99;
    static PPD = 14.99;
    static PPT = 14.99;

    // Colors
    static CheckinPinGrey = "#888888";
    static DefaultStopColor = "#E91E63";
    static DefaultSiteColor = "#40A23F";
    static DefaultTaskColor = "#E91E63";

    // Proof of Delivery
    static DefaultProofOfDelivery = PODDefaultSettings.None;

    // Images
    static IconRoundTripFlag = "/images/icons/icc_pin_flag_rt.png";
    static IconGreenFlag = "/images/icons/icc_pin_flag_green.png";
    static IconStartRing = "/images/icons/start_ring.png";
    static IconCompleteAll = "/images/icons/complete.png";
    static IconRoofPin = "/images/icons/pins/PinRoof.png";
    static IconRoadPin = "/images/icons/pins/PinRoad.png";
    static IconRoofPinFaded = "/images/icons/pins/PinRoofFaded.png";
    static IconRoadPinFaded = "/images/icons/pins/PinRoadFaded.png";
    static IconTargetLight = "/images/icons/targetPrimary.png";
    static IconTargetDark = "/images/icons/targetPrimary.png";

    //Drivers
    static FormattedAccessTypes = ["Restricted", "Normal", "Elevated", "Dispatcher (Restricted)"];

    //Fortifi
    static VIDCookieName = "FRT:VIS";

    // Pin Cache
    static PinCompCluster = "pincompcluster";

    // Here
    
    static hereApiKey = "Rhkg2ULk-86JaxU16kSpE1NlPnAA1LvrEAFAxRdN1V0";

    // TUS
    static tusUploadEndpoint = "https://tus.aws.mapquest.com/";
    static tusFileURL = "https://tus.aws.mapquest.com/file/";
    
    // Firebase
    static FirebaseKey: string = "BBUmNBFtA_YE2BG8eMWjD-uyEYIBztZICkru1W9g3SJMQ3Dfr9f9AKbPj3EI-jEOU6Il1cNgiEN4K7DciAw5hgg";

    static FirebaseConfig = {
        apiKey: "AIzaSyBU0pveOtdPWeMwjzZwc0FxqWkqEo6TwkI",
        authDomain: "roadwarrior-mobile.firebaseapp.com",
        databaseURL: "https://roadwarrior-mobile.firebaseio.com",
        projectId: "roadwarrior-mobile",
        storageBucket: "roadwarrior-mobile.appspot.com",
        messagingSenderId: "555634752094",
        appId: "1:555634752094:web:6134fea175bc95085e92cf",
        measurementId: "G-E1THMV42VN"
    };


    static getSocketBase(baseUrl: string): string {
        //static SvcUriSocket = RwConstants.SvcUri
        let socketUrl = baseUrl
            .replace("https", "wss")
            .replace("http", "ws")
            .replace("roadwarrior.svc", "rwWebsocket.svc");
        return socketUrl;
    }

    // Onboard tips timers
    static OnboardTipsDelay = 2000;
    static OnboardArrowDuration = 7000;
    static OnboardUploadsPopupDelay = 5000;
    static OnboardUploadsPopupDuration = 15000;
    static OnboardUploadsActiveTimer = 2000;

    // POD tutorial timers
    static PODSettingsPopupDelay = 1000;
    static PODSettingsPopupDuration = 10000;

}




