

import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import theGlobals, {RwGlobals} from "@/app/RwGlobals";
import thePlanSpace, {RwPlannerSpace} from "@/app/views/planner/RwPlannerSpace";
import {RwPrefUtils} from "@/app/utils/RwPrefUtils";
import {RwConstants} from "@/app/RwConstants";
import {RwTextUtils} from "@/app/utils/RwTextUtils";
import {RwGeoPoint} from "@/app/utils/RwGeoUtils";
import RwSearchView from "@/app/views/planner/RwSearchView.vue";
import RwSearchPanel from "@/app/views/planner/RwSearchPanel.vue";
import {RwPin} from "@/app/dem/RwPin";
import {RwPinTypes} from "@/app/RwEnums";
import {RwStop} from "@/app/dem/RwStop";

@Component({
  components: {RwSearchPanel, RwSearchView}
})
export default class RwReloPicker extends Vue {
  @Prop(String) readonly initTitle: string;
  @Prop(Number) readonly initLat: number;
  @Prop(Number) readonly initLng: number;

  //#region Props

  centerLat = 0;
  centerLng = 0;


  map: H.Map;
  mapui: H.ui.UI;
  platform: H.service.Platform;
  lightStyle: H.map.Style;
  darkStyle: H.map.Style;
  mapBehaviors: H.mapevents.Behavior;
  defaultLayers: H.service.Platform.MapTypes31;


  get globals(): RwGlobals {
    return theGlobals;
  }

  get space(): RwPlannerSpace {
    return thePlanSpace;
  }

  get title(): string {
    if (theGlobals.showAddReport) {
      return `Correct Address: ${this.globals.addReport?.address}`;
    } else if (theGlobals.showAddMissingReport) {
      return `Select New Location`;
    } else {
      return `Relocate ${this.initTitle}`;
    }
  }

  get selectBtnTitle(): string {
    return this.globals.showAddReport || this.globals.showAddMissingReport ? 'Use Location' : 'Apply';
  }

  get backColor() {
    return this.globals.isDarkMode ? "rwBackDark" : "rwBackLight";
  }

  get loading(): boolean {
    return this.globals.isLoading;
  }

  get isDarkMode(): boolean {
    return this.globals.isDarkMode;
  }

  get coordText(): string {
    let text = RwTextUtils.formatCoords(this.centerLat, this.centerLng)
    return text;
  }

  //#endregion Props

  @Watch("globals.centerMapData")
  watchCenterMapData(val) {
    if (val.lat != 0 || val.lng != 0) {
      this.centerLat = val.lat;
      this.centerLng = val.lng;
      const centroid = new H.geo.Point(val.lat, val.lng);
      this.map.setCenter(centroid, true);
    }
  }


  private mapInit() {

    let self = this;
    let apiKey = RwConstants.hereApiKey;
    this.platform = new H.service.Platform({'apikey': apiKey});
    this.defaultLayers = this.platform.createDefaultLayers();
    let layer = self.defaultLayers.vector.normal.map;

    let options: H.Map.Options = {
      zoom: RwPrefUtils.viewZoom,
      center: {
        lat: this.initLat,
        lng: this.initLng
      },
      pixelRatio: window.devicePixelRatio || 1
    };

    let mapContainer = this.$refs.reloMap as Element;
    this.map = new H.Map(mapContainer, layer, options);
    let events = new H.mapevents.MapEvents(this.map);
    this.mapBehaviors = new H.mapevents.Behavior(events);
    this.mapui = H.ui.UI.createDefault(this.map, self.defaultLayers);

    this.configMapControls();
    this.configMapEvents();
    this.configStyles();
    this.setMapStyle();

    if (this.globals.showAddReport) {
      const pin = new RwPin();
      pin.type = RwPinTypes.Stop;
      pin.stop = new RwStop();
      pin.baseColor = "#E2004F";
      Vue.nextTick(() => {
            pin.dataUrlAsync().then(dataUrl => {
              let iconSize = self.globals.getIconSize(pin.type);
              let anchor = self.globals.getAnchorPoint(pin.type);
              let iconOpts = {} as H.map.Icon.Options;
              if (anchor) iconOpts.anchor = anchor;
              if (iconSize) iconOpts.size = iconSize;
              let icon = new H.map.Icon(dataUrl, iconOpts);
              const marker = new H.map.Marker({lat: this.initLat, lng: this.initLng});
              marker.setIcon(icon);
              this.map.addObject(marker);
            });
          }
      );
    }
  }


  private configMapControls() {

    this.mapui.getControl("mapsettings").setVisibility(false);
    let zoom = this.mapui.getControl('zoom');
    zoom.setAlignment(H.ui.LayoutAlignment.RIGHT_MIDDLE);
    let imprint = this.map.getImprint();
    if (imprint) {
      if (this.globals.isDarkMode) {
        imprint.setOptions({invert: true});
      } else {
        imprint.setOptions({invert: false});
      }
    }

  }


  configMapEvents() {
    this.map.addEventListener("mapviewchangeend", () => {
      let centroid = this.map.getCenter();
      if (centroid) {
        this.centerLat = centroid.lat;
        this.centerLng = centroid.lng;
        thePlanSpace.reloCoordsLng = centroid.lng;
        thePlanSpace.reloCoordsLat = centroid.lat;
      }
    });
  }


  private configStyles() {
    let urlLight = `${RwConstants.CoreUri}/img/mapLight.yaml`;
    this.lightStyle = new H.map.Style(urlLight, 'https://js.api.here.com/v3/3.1/styles/omv/');

    let urlDark = `${RwConstants.CoreUri}/img/mapDark.yaml`;
    this.darkStyle = new H.map.Style(urlDark, 'https://js.api.here.com/v3/3.1/styles/omv/');
  }


  private setMapStyle() {

    let self = this;
    if (self.map) {

      let layer = self.defaultLayers.vector.normal.map;
      self.map.setBaseLayer(layer);
      const provider = layer.getProvider();

      if (provider) {
        if (self.isDarkMode) {
          provider.setStyle(self.darkStyle);
        } else {
          provider.setStyle(self.lightStyle);
        }
      }

      this.resizeMapViewDelayed(1000);
    }
  }


  resizeMapViewDelayed(delay: number) {
    let self = this;
    setTimeout(function () {
      self.resizeMapView("resizeMapViewDelayed");
    }, delay);
  }


  resizeMapView(src: string) {
    if (this.map) {
      this.map.getViewPort().resize();
    }
  }

  //#region User Events


  onEscape(event: Event) {
    event.stopPropagation();
    this.close();
  }


  onCommit() {
    let lat = this.centerLat;
    let lng = this.centerLng;
    let coord = new RwGeoPoint(lat, lng);
    //console.log("RwColorPicker.onCommit coord", lat, lng);
    if (this.globals.showAddReport && coord.lat.toFixed(6) === this.globals.addReport.lat.toFixed(6) &&
        coord.lng.toFixed(6) === this.globals.addReport.lng.toFixed(6)) {
      this.globals.showSnack("Please select a gps location that is different than the current location.");
    } else {
      this.$emit("onReloPick", coord);
      this.close();
    }

  }


  onCancel() {
    this.close();
  }

  close() {
    this.globals.showSearchPanel = false;
    this.globals.m_showReloPicker = false;
  }


  //#endregion UI Nav


  //#region LifeCycle

  mounted() {
    //console.log('RwReloPicker.mounted initLat, initLng', this.initLat, this.initLng);
    this.centerLat = this.initLat;
    this.centerLng = this.initLng;
    this.mapInit();
  }

  //#endregion


}

